import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Notes on MDX"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`hello`}</h1>
    <h2>{`world`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const add = (x, y) => {
  return x + y
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://rm-phoenix.co.uk"
      }}>{`this is a link`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      